<script setup lang="ts">
import { onMounted } from 'vue';

interface Props {
  scrollerContainer?: string;
}
const props = defineProps<Props>();

const { $gsap } = useNuxtApp();
const animate = () => {
  const selector = $gsap.utils.selector('.star-background');
  $gsap.to(selector('.stars-layer-1'), {
    scrollTrigger: {
      trigger: '.star-background',
      start: 'top 80%',
      scroller: props.scrollerContainer ? props.scrollerContainer : window,
      toggleActions: 'play pause resume pause',
    },
    backgroundPosition: '55px -2000px',
    duration: 100,
    ease: 'none',
    repeat: -1,
  });

  $gsap.to(selector('.stars-layer-2'), {
    scrollTrigger: {
      trigger: '.star-background',
      start: 'top 80%',
      toggleActions: 'play pause resume pause',
      scroller: props.scrollerContainer ? props.scrollerContainer : window,
    },
    backgroundPosition: '0px -2000px',
    duration: 75,
    ease: 'none',
    repeat: -1,
  });
};
const themeStore = useThemeStore();
onMounted(() => {
  if (themeStore.animationsEnabled) {
    animate();
  }
});

onUnmounted(() => {
  if (themeStore.animationsEnabled) {
    $gsap.killTweensOf('.star-background *');
  }
});
</script>

<template>
  <div class="star-background w-full h-full bg-purple-950 relative">
    <div class="stars-layer-1 absolute bg-[url('/images/stars.svg')] block w-full h-full bg-[200%_auto] bg-[55px_100px]" />
    <div class="stars-layer-2 absolute bg-[url('/images/stars.svg')] block w-full h-full bg-cover bg-[0px_0px]" />
    <slot />
  </div>
</template>
